<template>
<div class="header-main-pc">

    <div class="header-left">
        <router-link :to="'/'" style="text-decoration: none;" class="logo op-trk-event">
            <img src="../assets/logoo.png" style="width: 130px;">
        </router-link>
        <!-- <a href="index.html" class="logo op-trk-event">
            <img src="logoo.png" style="width: 130px;">
        </a> -->
        <ul class="nav">

            <li class="op-trk-event">
                <router-link :to="'/product'" style="text-decoration: none;">产品列表</router-link>
            </li>

            <li class="op-trk-event">
                <router-link :to="'/solution'" style="text-decoration: none;">解决方案</router-link>
            </li>

            <li class="op-trk-event">
                <router-link :to="'/about'" style="text-decoration: none;">关于我们</router-link>
            </li>

            <li class="op-trk-event">
                <a href="https://ypt.inrunonline.com/admin/login" target="_blank">云平台</a>
            </li>

        </ul>
        <!-- <ul class="nav">

            <li @mouseleave="navOver(-1)" class="op-trk-event" tm-event_name="click_navigation" tm-module="Top Navigation" tm-level="first" tm-nav_group="OPPO 产品" tm-nav_name="OPPO 产品">
                <a href="product.html" style="text-decoration: none;" @click="navOver(0, $event)" target="_self">产品列表</a>

            </li>

            <li @mouseleave="navOver(-1)" class="op-trk-event" tm-event_name="click_navigation" tm-module="Top Navigation" tm-level="first" tm-nav_group="解决方案" tm-nav_name="解决方案">
                <a href="hasim.html" style="text-decoration: none;" @click="navOver(1, $event)" target="_self">解决方案</a>

            </li>

            <li @mouseleave="navOver(-1)" class="op-trk-event" tm-event_name="click_navigation" tm-module="Top Navigation" tm-level="first" tm-nav_group="关于我们" tm-nav_name="关于我们">
                <a href="about.html" style="text-decoration: none;" @click="navOver(2, $event)" target="_self">关于我们</a>

            </li>

            <li @mouseleave="navOver(-1)" class="op-trk-event" tm-event_name="click_navigation" tm-module="Top Navigation" tm-level="first" tm-nav_group="云平台" tm-nav_name="云平台">
                <a @click="navOver(3, $event)" target="_self">云平台</a>
            </li>

        </ul> -->
    </div>
</div>
<div class="header-main-mo">
    <div class="header-first">
        <div class="first-nav">
            <router-link :to="'/'" class="nav-logo op-trk-event">
                <img src="../assets/logoo.png" style="width: 130px;">
            </router-link>

            <ul class="header-box-right">
                <li class="nav-menu" @click="menuShow()"><em class="fill"></em></li>
            </ul>
        </div>
    </div>
    <div class="header-menu" v-if="show">
        <div class="header-menu-box" style="height: auto;">
            <ul class="second-nav">
                <!-- <li>
                    <p class="second-nav-title op-trk-event">产品列表</p>
                </li>
                <li>
                    <p class="second-nav-title op-trk-event">解决方案</p>
                </li>
                <li>
                    <p class="second-nav-title op-trk-event">关于我们</p>
                </li> -->
                

                <li>
                    <router-link class="second-nav-title op-trk-event" :to="'/product'" style="text-decoration: none;">产品列表</router-link>
                </li>

                <li>
                    <router-link class="second-nav-title op-trk-event" :to="'/solution'" style="text-decoration: none;">解决方案</router-link>
                </li>

                <li>
                    <router-link class="second-nav-title op-trk-event" :to="'/about'" style="text-decoration: none;">关于我们</router-link>
                </li>
            </ul>
            
        </div>
    </div>
    
</div>
</template>

<script>
export default {
   
    data() {
        return {
            show:false,
        }
    },
    created() {
        
    },
    methods: {
        menuShow(){
            this.show = !this.show
        }
    }
}
</script>