// import {createApp} from "vue";
import {createRouter,createWebHistory} from "vue-router";
import Home from '../views/home/index.vue'
const routerHistory = createWebHistory()
// createApp().use(createRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component:  Home
  },{
    path: '/product',
    name: 'product',
    component: () => import('../views/pro/index.vue')
  },{
    path: '/product-info/:id',
    name: 'productInfo',
    component: () => import('../views/pro/info.vue')
  },{
    path:'/solution',
    name: 'solution',
    component: () => import('../views/solution/index.vue')
  },{
    path: '/solution-info/:id',
    name: 'solutionInfo',
    component: () => import('../views/solution/info.vue')
  },{
    path:'/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  }

]

const router = createRouter({
	// mode: 'history',
  history: routerHistory,
  routes,
});
export default router
