import axios from "axios";
// import { useMessage } from 'naive-ui'
// axios.defaults.baseURL = "http://localhost:8080/jeecg-boot";
axios.defaults.baseURL = "https://www.inrunonline.com/api/";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "application/json";

// axios.interceptors.request.use(
//   config => {

//     if (config.url == '/auth/oauth/token') {
//       config.headers.Authorization = 'Basic dGVzdDp0ZXN0'
//       config.headers['Content-Type']="application/x-www-form-urlencoded"
//     }else{
//       const token = store.state.token;
//       token && (config.headers.Authorization = 'Bearer '+token);
//     }
//     return config;
//   })

axios.interceptors.response.use((response) => {
  if (response && response.status == 200) {
    if (response.config.url == "/code") {
      return Promise.resolve(response);
    } else if (response.data.code == 0) {
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(response);
    }
  } else {
    return Promise.resolve(response);
  }
});
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        if (res) {
          resolve(res.data);
        }
      });
  });
}
