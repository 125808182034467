import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import {marked} from 'marked';
const markedMixin = {
    methods: {
         md: function (input) {
            return marked (input);
        },
    },
};
// import 'bootstrap/dist/css/bootstrap.css' //引用bootstrap的样式
// import 'bootstrap/dist/js/bootstrap.min.js' //引用bootstrap的js
// import './assets/css/clientlib-design.min.css'
// import './assets/css/clientlib-font-cn.min.css'
// import './assets/css/clientlib-home.min.css'
createApp(App).use(router).mixin(markedMixin).mount('#app')