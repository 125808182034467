<template>
<section class="main">
    <nav class="nav ft-body-3-1 bottom-line">

        <dl>
            <dt class="ft-body-2-1">联系我们</dt>

            <dd>
                <span class="op-trk-event">
                    全国热线：400-9627-818
                </span>

            </dd>

            <dd>
                <span class="op-trk-event">
                    公司地址：北京市丰台区芳城园一区17号日月天地B座2607
                </span>
            </dd>

            <!-- <div class="cp-plus-minus-btns">
                <svg class="op-icon plus-btn">
                    <use xlink:href="#icon-plus"></use>
                </svg>
                <svg class="op-icon minus-btn">
                    <use xlink:href="#icon-minus"></use>
                </svg>
            </div> -->
        </dl>
        <!-- style="margin-right: 40px;width: 210px;" -->
        <dl>
            <dt class="ft-body-2-1">商务合作</dt>

            <dd>
                <span class="op-trk-event">联系人：陆先生</span>
            </dd>
            <dd>
                <span class="op-trk-event">联系电话：18611272737</span>
            </dd>

            <!-- <div class="cp-plus-minus-btns">
                <svg class="op-icon plus-btn">
                    <use xlink:href="#icon-plus"></use>
                </svg>
                <svg class="op-icon minus-btn">
                    <use xlink:href="#icon-minus"></use>
                </svg>
            </div> -->
        </dl>
        <dl>
            <dt class="ft-body-2-1">商务合作</dt>

            <dd>
                <span class="op-trk-event">联系人：张先生</span>
            </dd>
            <dd>
                <span class="op-trk-event">联系电话：13466656569</span>
            </dd>

            <!-- <div class="cp-plus-minus-btns">
                <svg class="op-icon plus-btn">
                    <use xlink:href="#icon-plus"></use>
                </svg>
                <svg class="op-icon minus-btn">
                    <use xlink:href="#icon-minus"></use>
                </svg>
            </div> -->
        </dl>
        <dl>

            <dd>
                <div class="clearfix text-right footer-servies">

                    <div class="contact_info">
                        <img src="../assets/18091680880645_.pic.jpg">
                        <div class="text-center">E尚网小程序</div>
                    </div>
                    <div class="contact_info">
                        <img src="../assets/18101680880697_.pic_thumb.jpg">
                        <div class="text-center">E尚网公众号</div>
                    </div>
                </div>

            </dd>

            <!-- <div class="cp-plus-minus-btns">
                <svg class="op-icon plus-btn">
                    <use xlink:href="#icon-plus"></use>
                </svg>
                <svg class="op-icon minus-btn">
                    <use xlink:href="#icon-minus"></use>
                </svg>
            </div> -->
        </dl>

    </nav>
</section>
<section class="bottom">
    <ul class="links ft-body-3">
        
            <li>
                <a href="https://beian.miit.gov.cn" target="_blank">
                    京ICP备18007938号-1
                </a>
            </li>
        
        <li class="rights">
            <a href="https://beian.miit.gov.cn/" target="_blank">© 2017 - 2023 云软 版权所有 京ICP备18007938号-1  </a>
            
        </li>
    </ul>
</section>
</template>

<style>
.text-right {
    display: flex;
    text-align: right;
}

.contact_info {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    color: #999;
}

.text-left {
    text-align: left;
}

.contact_info>img {
    width: 120px;
}

.text-center {
    text-align: center !important;
}

.company_adress {
    margin-top: 25px;
    padding-right: 10px;
    float: right;
}

.company_adress img {
    vertical-align: top;
    margin: 2px 0 0 8px;
}

.company_adress span {
    display: inline-block;
    font-size: 14px;
    color: #999;
    margin-left: 8px;
    vertical-align: top;
    letter-spacing: 1px;
}

#footer .main nav dl {
    margin-right: 40px;
    width: 210px;
}

@media screen and (max-width: 767.98px) {
    #footer .main nav dl {
        max-height: 256px;
        width: auto;
    }

    #footer .main nav dl dd {
        height: auto;
    }
}
</style>
