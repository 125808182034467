<template>
<div>
    <!-- <heads></heads> -->
    <router-view />
</div>
</template>

<!-- <script>
import heads from './assets/common/heads.vue'
export default {
    components: {
        
    },

}
</script> -->
