<template>
<body class="home-page page basicpage light-theme">
    <div id="oc-wrapper">
        <div class="header-v2 header-v2-cn" id="header-v2" style="display: block;">

            <Head></Head>
        </div>

        <section id="oc-container" style="margin-top: -28px">
            <div class="root responsivegrid">

                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                    <div class="responsivegrid aem-GridColumn aem-GridColumn--default--12">

                        <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                            <div class="customhtmlcomponent aem-GridColumn aem-GridColumn--default--12">

                                <div class="custom-html-component">
                                </div>

                            </div>
                            <div v-for="(item, i) in banner" :key="i">
                                <div class="hero-banner aem-GridColumn aem-GridColumn--default--12">
                                    <div class="cmp__hero-banner-v2 multiple  light-theme" data-time-format="dd-MM-YY">
                                        <div class="hero-banner-kv no-link">

                                            <picture>
                                                <source type="image/webp" :srcset="baseUrl + item.bannerM" media="(max-width: 650px)">
                                                <source :srcset="baseUrl + item.bannerM" media="(max-width: 650px)">
                                                <img class="hero-banner-img-bg lazyload" :src=" baseUrl + item.banner "  data-src-webp="true" />

                                            </picture>
                                            <div class="content-container responsive__layout content-left">
                                                <div class="top-layout">
                                                    <div class="title ft-headline-5 font-medium stop-prop">{{ item.name }}</div>
                                                    <div class="subtitle ft-subtitle-3-1 font-medium stop-prop">{{ item.remark }}</div>

                                                </div>
                                                <div class="bottom-layout">

                                                    <div class="btn-array font-medium ">
                                                        <div v-if="item.type == '1'" @click="toLink(item.linkId)" class="new-button new-button--primary middle bubblen-animete-btn ft-body-3-1   op-trk-event" style="text-decoration-line: none">了解更多</div>
                                                        <router-link v-if="item.type == '2'" :to="'/solution-info/'+item.linkId" class="new-button new-button--primary middle bubblen-animete-btn ft-body-3-1   op-trk-event" style="text-decoration: none;">了解更多</router-link>
                                                        <a v-if="item.type == '3'" :href="item.linkUrl" style="text-decoration-line: none"></a>

                                                    </div>
                                                    <div class="share-container large-show stop-prop">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="share-container large-hide stop-prop">

                                        </div>
                                    </div>
                                </div>
                                <div class="separator aem-GridColumn aem-GridColumn--default--12">
                                    <section class="cmp__separator">
                                        <div class="cmp-separator-box style-default cmp_C6QBtoFV"></div>

                                    </section>
                                </div>
                            </div>
                            <div class="separator aem-GridColumn aem-GridColumn--default--12">
                                <section class="cmp__separator">
                                    <div class="cmp-separator-box  cmp_jlpWBFIr"></div>

                                </section>
                            </div>
                            <div class="featured-products aem-GridColumn aem-GridColumn--default--12">
                                <div class="cmp__homepage-featured-product" style="background: ;">
                                    <div class="index__homepage-featured-product">

                                        <div class="responsive__layout">
                                            <div class="homepage-featured-product-header" style="margin-bottom: 0px;">
                                                <h2 class="header-text ft-subtitle-3-1">更多新品</h2>
                                            </div>

                                        </div>
                                        <template v-for="(item, i) in product" :key="i">
                                            <div class="responsive__layout" v-if="i%2==0">

                                                <div class="wrap col-2">
                                                    <div class="homepage-featured-product-container col-2">
                                                        <div class="homepage-featured-product-container-left">
                                                            <div>
                                                                <h2 class="homepage-featured-product-heading ft-subtitle-1 font-medium">
                                                                    {{item.name}}
                                                                    <!-- <sup class="tag ft-body-3-1" v-if="item.hot">热销</sup> -->
                                                                </h2>

                                                            </div>
                                                            <div class="homepage-featured-product-img">
                                                                <img class=" lazyloaded" :src="baseUrl + item.imgPathM">
                                                            </div>
                                                            <div class="homepage-featured-product-bottom box is-light" style="background: ;">
                                                                <p class="ft-body-2 homepage-featured-product-bottom-text" style="margin-bottom: 0px;">
                                                                    {{item.remark}}
                                                                </p>
                                                                <div class="homepage-featured-product-bottom-btns">
                                                                    <div class="new-button new-button--link op-trk-event is-light" @click="toLink(item.id)" style="text-decoration-line: none">了解更多</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="homepage-featured-product-container-right">
                                                            <img class="lazyload" :src="baseUrl + item.imgPath" alt="OPPO K10x" />
                                                        </div>
                                                    </div>
                                                    <div class="homepage-featured-product-container col-2">
                                                        <div class="homepage-featured-product-container-left">
                                                            <div>
                                                                <h2 class="homepage-featured-product-heading ft-subtitle-1 font-medium">
                                                                    {{product[i+1].name}}
                                                                    <!-- <sup class="tag ft-body-3-1" v-if="product[i+1].hot">热销</sup> -->
                                                                </h2>

                                                            </div>
                                                            <div class="homepage-featured-product-img">
                                                                <img class=" lazyloaded" :src="baseUrl + product[i+1].imgPathM">
                                                            </div>
                                                            <div class="homepage-featured-product-bottom box is-light" style="background: ;">
                                                                <p class="ft-body-2 homepage-featured-product-bottom-text" style="margin-bottom: 0px;">
                                                                    {{product[i+1].remark}}
                                                                </p>
                                                                <div class="homepage-featured-product-bottom-btns">
                                                                    <div @click="toLink(product[i+1].id)" class="new-button new-button--link op-trk-event is-light" style="text-decoration-line: none">了解更多</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="homepage-featured-product-container-right">
                                                            <img class="lazyload" :src="baseUrl + product[i+1].imgPath" alt="OPPO K10x" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>

                                    </div>
                                </div>
                            </div>
                            <div class="separator aem-GridColumn aem-GridColumn--default--12">
                                <section class="cmp__separator">
                                    <div class="cmp-separator-box  cmp_EHbko7ba"></div>

                                </section>
                            </div>

                            <div class="separator aem-GridColumn aem-GridColumn--default--12">
                                <section class="cmp__separator">
                                    <div class="cmp-separator-box style-default cmp_lh8xUHu9"></div>

                                </section>
                            </div>

                            <div class="general-content-card aem-GridColumn aem-GridColumn--default--12">
                                <div class="cmp__general-content-card pb80 light-theme" style="background: #E0E8EA;">
                                    <div class="responsive__layout">
                                        <div class="cmp__event-title pb40" style="padding-bottom: inherit;">
                                            <p class="title ft-subtitle-3-1"> 案例展示</p>
                                        </div>
                                        <div class="general-container swiper-container" style="margin-top: -50px;">
                                            <div class="swiper-wrapper">

                                                <div class="swiper-slide slide-item " v-for="(item,i) in cases" :key="i">
                                                    <a class="op-trk-event-img" href="javaScript:;" target="_self">
                                                        <div class="img-box">
                                                            <picture>
                                                                <img class="lazyload" :src="baseUrl + item.imgPath" alt="" data-src-webp="true" />
                                                            </picture>
                                                        </div>
                                                    </a>
                                                    <a class="op-trk-event-title" href="javaScript:;" target="_self" style="text-decoration-line: none">
                                                        <div class="content-box">
                                                            <p class="content-title ft-subtitle-3 font-medium op-trk-event">{{item.name}}</p>
                                                            <span class="content-desc ft-body-2">{{ item.remark }}</span>

                                                        </div>
                                                    </a>
                                                </div>

                                                <!-- <div class="swiper-slide slide-item ">
                                                    <a class="op-trk-event-img" href="javaScript:;" target="_self">
                                                        <div class="img-box">
                                                            <picture>
                                                                <img class="lazyload" src="../../assets/case/1.002.png" data-src-webp="true" />
                                                            </picture>
                                                        </div>
                                                    </a>
                                                    <a class="op-trk-event-title" href="javaScript:;" target="_self">
                                                        <div class="content-box">
                                                            <p class="content-title ft-subtitle-3 font-medium op-trk-event">盒马生鲜自提店</p>
                                                            <span class="content-desc ft-body-2">云软在线主要提供5G
                                                                云宽带产品给到门店收银系统设备使用，实现双线路冗余备份，避免网络故障导致系统订单未及时生成，影响客户服务体验。</span>

                                                        </div>
                                                    </a>
                                                </div>

                                                <div class="swiper-slide slide-item ">
                                                    <a class="op-trk-event-img" href="javaScript:;" target="_self">
                                                        <div class="img-box">
                                                            <picture>

                                                                <img class="lazyload" src="../../assets/case/1.003.png" alt="" />
                                                            </picture>
                                                        </div>
                                                    </a>
                                                    <a class="op-trk-event-title" href="javaScript:;" target="_self">
                                                        <div class="content-box">
                                                            <p class="content-title ft-subtitle-3 font-medium op-trk-event">OPPO品牌北京线下销售门店</p>
                                                            <span class="content-desc ft-body-2">云软在线主要提供现场门店收银系统中的网络接入，使用产品为5G
                                                                云宽带设备，5G产品实现高稳定性、低延时等特点。极大提高了门店系统的带宽稳定性，同时支持有线+5G双线备份，稳定性大幅提高。</span>

                                                        </div>
                                                    </a>
                                                </div> -->

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="customhtmlcomponent aem-GridColumn aem-GridColumn--default--12">

                                <div class="custom-html-component">

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>

        <span>

            <section class="light-theme">
                <div id="footer">
                    <Foot></Foot>
                </div>
            </section>
        </span>

    </div>

</body>
</template>

<script>
import {
    indexData,
    productInfo
} from '@/request/api'
import Head from '../../components/heads.vue'
import Foot from '../../components/footer.vue'
export default {
    components: {
        Head,
        Foot
    },
    data() {
        return {
            banner: [],
            product: [],
            cases: [],
            baseUrl: "https://www.inrunonline.com/api/sys/common/static/"
        }
    },
    created() {
        indexData().then((res) => {
            let data = res.result;
            this.banner = data.banner;
            this.product = data.product;
            this.cases = data.cases;
        })
    },
    methods: {
        toLink(id) {
            productInfo({
                id: id
            }).then((res) => {
                let data = res.result;
                if (data && data.details) {
                    this.$router.push({
                        name: 'productInfo',
                        params: {
                            id: id
                        }
                    })
                }

            })
        }
    }
}
</script>

<style>
body.app-mode .cp-site-language-tip {
    display: none !important;
}

body.app-mode .cp-notice-bar {
    display: none !important;
}

body.app-mode #oc-header {
    display: none !important;
}

body.app-mode .new-header {
    display: none !important;
}

body.app-mode #header-v2 {
    display: none !important;
}

body.app-mode #footer {
    display: none !important;
}

body.app-mode #oc-container {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

body.app-mode #oc-wrapper {
    padding-top: 0 !important;
}
</style>
<style>
.oh-nav-pcseek {
    position: relative;
    z-index: 999;
}

/* 导航购物车图标 */
@media(max-width: 767.98px) {
    body #footer .contact .contact-tab .tab-card .tab-card-wrap {
        padding-left: 0;
    }
}
</style>

<style>
@media screen and (max-width: 768px) {

    body.support-home-page.show-cookie-tip .sow_black,
    body.support-page-stand.show-cookie-tip .sow_black,
    body.support-page.show-cookie-tip .sow_black {
        bottom: .64rem !important;
    }

    .support-home-page .sow_black,
    .support-page-stand .sow_black,
    .support-page .sow_black {
        bottom: 12px !important;
    }

    .oh-nav-mbseek .user-info .user-pic img {
        width: 100%;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1919px) {

    body.support-home-page.show-cookie-tip .sow_customerIcon,
    body.support-page-stand.show-cookie-tip .sow_customerIcon,
    body.support-page.show-cookie-tip .sow_customerIcon {
        transition: bottom .3s;
        bottom: 125px !important;
    }

    body.support-home-page .sow_customerIcon,
    body.support-page-stand .sow_customerIcon,
    body.support-page .sow_customerIcon {
        transition: bottom .3s;
        bottom: 68px !important;
    }
}

@media only screen and (min-width: 1920px) and (max-width: 999999px) {

    body.support-home-page.show-cookie-tip .sow_customerIcon,
    body.support-page-stand.show-cookie-tip .sow_customerIcon,
    body.support-page.show-cookie-tip .sow_customerIcon {
        transition: bottom .3s;
        bottom: 142px !important;
    }

    body.support-home-page .sow_customerIcon,
    body.support-page-stand .sow_customerIcon,
    body.support-page .sow_customerIcon {
        transition: bottom .3s;
        bottom: 85px !important;
    }
}
</style>
<style>
#oc-header .oh-nav-submenus-wrapper .pc-nav-series-products .oh-nav-series-product-list .swiper-slide .pTitle {
    max-width: 200px;
}

.header-v2 .header-main-pc .header-right .search::before,
.header-v2 .header-main-mo .header-menu .search::before {
    content: '';
    display: none;
}
</style>

<style>
#footer nav dl {
    width: 200px
}

@media (max-width: 767.98px) {
    #footer nav dl {
        width: 100%
    }
}

@media (max-width: 1024px) {
    body.clean-theme #oc-container {
        margin-top: -50px;
    }

    .header-v2 .header-main-mo .header-menu.search-show {
        overflow-x: unset;
    }

    .header-v2 .header-main-mo .header-menu.search-hide {
        overflow-x: hidden;
    }
}
</style>
<style>
@media screen and (max-width: 650px) {
    .index__homepage-featured-product .homepage-featured-product-container .homepage-featured-product-img {
        margin: 0;
    }
}

.index__homepage-featured-product .col-2 .homepage-featured-product-img img {
    max-width: 320px;
}

.index__homepage-featured-product .col-2 .homepage-featured-product-container .homepage-featured-product-container-right img {
    max-width: 320px;
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
    .index__homepage-featured-product .homepage-featured-product-container .homepage-featured-product-bottom.box {
        width: 180px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .index__homepage-featured-product .homepage-featured-product-container .homepage-featured-product-bottom.box {
        width: 220px;
    }
}

@media screen and (max-width: 650px) {
    .index__homepage-featured-product .col-2 .homepage-featured-product-container .homepage-featured-product-container-left .homepage-featured-product-img img {
        width: 100%;
    }
}

.homepage-featured-product-heading {
    white-space: nowrap;
}

@media screen and (min-width: 500px) and (max-width: 650px) {
    .index__homepage-featured-product .homepage-featured-product-container {
        height: 600px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
    .index__homepage-featured-product .responsive__layout .wrap {
        height: 450px;
    }
}
</style>
<style>
.oh-nav-pcseek {
    z-index: 6;
    position: relative;
}

@media (max-width: 1439.98px) and (min-width: 1024px) {
    #footer nav {
        width: 100% !important;
    }

    #footer nav dl {
        width: 170px !important;
    }
}

@media (max-width: 1023px) and (min-width: 980px) {
    #footer nav {
        width: 100% !important;
    }

    #footer nav dl {
        width: 110px !important;
    }
}

@media screen and (max-width: 650px) {

    .cmp__hero-banner-v2.popup .hero-banner-kv,
    .cmp__hero-banner-v2.multiple .hero-banner-kv {
        min-height: 530px;
    }
}
</style>
<style>
.cmp-separator-box.cmp_EHbko7ba {
    background: transparent;
    height: 80px;
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
    .cmp-separator-box.cmp_EHbko7ba {
        background: transparent;
        height: 48px;
    }
}

@media screen and (max-width: 650px) {
    .cmp-separator-box.cmp_EHbko7ba {
        background: transparent;
        height: 64px;
    }
}
</style>
<style>
.cmp-separator-box.cmp_lh8xUHu9 {
    background: #E0E8EA;
    height: 80px;
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
    .cmp-separator-box.cmp_lh8xUHu9 {
        background: #E0E8EA;
        height: 48px;
    }
}

@media screen and (max-width: 650px) {
    .cmp-separator-box.cmp_lh8xUHu9 {
        background: #E0E8EA;
        height: 64px;
    }
}
</style>
<style>
.cmp-separator-box.cmp_jlpWBFIr {
    background: transparent;
    height: 80px;
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
    .cmp-separator-box.cmp_jlpWBFIr {
        background: transparent;
        height: 48px;
    }
}

@media screen and (max-width: 650px) {
    .cmp-separator-box.cmp_jlpWBFIr {
        background: transparent;
        height: 64px;
    }
}
</style>
<style>
.cmp-separator-box.cmp_WRm75T57 {
    background: transparent;
    height: 6px;
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
    .cmp-separator-box.cmp_WRm75T57 {
        background: transparent;
        height: 4px;
    }
}

@media screen and (max-width: 650px) {
    .cmp-separator-box.cmp_WRm75T57 {
        background: transparent;
        height: 4px;
    }
}
</style>
<style>
.cmp-separator-box.cmp_C6QBtoFV {
    background: transparent;
    height: 6px;
}

@media screen and (min-width: 651px) and (max-width: 1024px) {
    .cmp-separator-box.cmp_C6QBtoFV {
        background: transparent;
        height: 4px;
    }
}

@media screen and (max-width: 650px) {
    .cmp-separator-box.cmp_C6QBtoFV {
        background: transparent;
        height: 4px;
    }
}
</style>
<style>
a {
    text-decoration-line: none;
}
</style>
